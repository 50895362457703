@import "base";
main#main
{
  text-align: center;
  padding-top: 20px;
  color:#cccccc;
  padding-left: 17px;
  padding-right: 17px;
}

.centre
{
    text-align: center;
}