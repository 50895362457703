body
{
	background-color: #222222;
	color:#dddddd;
	font-family: "Helvetica Neue","Arial","sans-serif";
	font-size: 16px;
	line-height: 1.6em;
	letter-spacing: 0em;
	font-weight: normal;
	font-style: normal;
}

code
{
	color: #ffffff;
	background-color: #444444;
	padding-left: 2px;
	padding-right: 2px;
	padding-bottom: 1px;
	border-radius: 3px;
}

div.container
{
	padding-top: 50px;
	padding-left: 5px;
	padding-right: 5px;
	padding-bottom: 10px;
	width: calc(100% - 3em);
	max-width: 76em;
	margin: 0 auto;
	text-align: center;
}

p.site-tagline
{
	font-size: 14px;
	line-height: 1.2em;    
	text-transform: uppercase;
    text-decoration: none;
    letter-spacing: .05em;
    font-weight: normal;
    font-style: normal;
    color: #ddd;
}

nav.nav ul
{
	padding-left: 0;
	text-align: center;
	margin-top: 0;
}

li.nav-item
{
	display: inline-block;
	margin-right: 0.1em;
}

li.nav-item a
{
	text-transform: uppercase;
	font-size: 14px;
	text-decoration: none;
	font-weight: bold;
	letter-spacing: .05em;
	line-height: 1.2em;
}

h1.site-title a
{
	text-transform: uppercase;
	text-decoration: none;
	font-size: 64px;
	font-weight: bold;

	/* This was cool, might as well keep it
	background-clip: text;
    text-fill-color: transparent;
    background-color:#dddddd;
    background-image: linear-gradient(90deg, rgba(91,206,250,1) 0%, rgba(91,206,250,1) 20%, rgba(245,169,184,1) 20%, rgba(245,169,184,1) 40%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 60%, rgba(245,169,184,1) 60%, rgba(245,169,184,1) 80%, rgba(91,206,250,1) 80%, rgba(91,206,250,1) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent; 
	-moz-background-clip: text;
	-moz-text-fill-color: transparent;*/
}

@media (orientation:portrait) and (max-width: 25em)
{
	h1.site-title a
	{
		font-size: 16vw;
	}
}

h1.site-title a span:nth-child(odd)
{
	color: #5BCEFA;
}

h1.site-title a span:nth-child(3)
{
	color: #FFFFFF;
}

h1.site-title a span:nth-child(even)
{
	color: #F5A9B8;
}

a
{
	text-decoration: underline;
	text-transform: none;
}

a:visited
{
	color:#dddddd;
}

h1.site-title a:hover
{
	color:#dddddd;
}

a:hover
{
	color:#999999;
}

h1.cent-head
{
	font-size: 2em;
	text-align: center;
}

div.line
{
	background-color: #dddddd;
	padding-top: 1px;
	margin: 0 auto;
	border: none;
	width: 70%;
}

section#social
{
	display:block;
	float:left;
	height:48px;
	width:100%;
	text-align: center;
	padding-bottom: 12px;
}

section#social > a, section#social > a > img
{
	height:inherit;
	margin-left:10px;
}

section#social > a, section#social > a:first-child > img
{
	margin-left:0px;
}